/**
 * Checks if given link contains an anchor to element on current page
 * @param {String} link - link value form typo3 API
 * @returns {Boolean} true if link contains an anchor to element on current page, otherwise false
 */
export const isScrollToLink = (link: string | null | undefined): boolean => {
  // link to component in typo3 should generate element with id starts with 'c' and internal component id e.g. c1234
  // so the anchor to element will be #c1234
  return !!link?.startsWith('#c')
}

/**
 * Scrolls window to an element with given CSS selector
 * @param {String} elementSelector - CSS element selector e.g. '#mySuperElementToScrollTo'
 * @param {Number} [offset=0] - an offset from element top in px
 */
export const scrollToElement = (elementSelector: string | null | undefined, offset: number = 0) => {
  if (!elementSelector) return
  const element = document?.querySelector(elementSelector)
  if (element) {
    window.scrollTo({
      behavior: 'smooth',
      top: element.getBoundingClientRect().top - document?.body?.getBoundingClientRect().top - offset
    })
  }
}
